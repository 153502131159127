/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIngredient = /* GraphQL */ `
  mutation CreateIngredient(
    $input: CreateIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    createIngredient(input: $input, condition: $condition) {
      id
      name
      stock
      minimum
      purchaseQuantity
      productIngredient {
        items {
          id
          quantity
          createdAt
          updatedAt
          ingredientProductIngredientId
          productProductIngredientId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredient = /* GraphQL */ `
  mutation UpdateIngredient(
    $input: UpdateIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    updateIngredient(input: $input, condition: $condition) {
      id
      name
      stock
      minimum
      purchaseQuantity
      productIngredient {
        items {
          id
          quantity
          createdAt
          updatedAt
          ingredientProductIngredientId
          productProductIngredientId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredient = /* GraphQL */ `
  mutation DeleteIngredient(
    $input: DeleteIngredientInput!
    $condition: ModelIngredientConditionInput
  ) {
    deleteIngredient(input: $input, condition: $condition) {
      id
      name
      stock
      minimum
      purchaseQuantity
      productIngredient {
        items {
          id
          quantity
          createdAt
          updatedAt
          ingredientProductIngredientId
          productProductIngredientId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      fangId
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      fangId
      name
      productIngredient {
        items {
          id
          quantity
          createdAt
          updatedAt
          ingredientProductIngredientId
          productProductIngredientId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      fangId
      name
      productIngredient {
        items {
          id
          quantity
          createdAt
          updatedAt
          ingredientProductIngredientId
          productProductIngredientId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSale = /* GraphQL */ `
  mutation CreateSale(
    $input: CreateSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    createSale(input: $input, condition: $condition) {
      id
      product {
        id
        fangId
        name
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      date
      createdAt
      updatedAt
      saleProductId
    }
  }
`;
export const updateSale = /* GraphQL */ `
  mutation UpdateSale(
    $input: UpdateSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    updateSale(input: $input, condition: $condition) {
      id
      product {
        id
        fangId
        name
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      date
      createdAt
      updatedAt
      saleProductId
    }
  }
`;
export const deleteSale = /* GraphQL */ `
  mutation DeleteSale(
    $input: DeleteSaleInput!
    $condition: ModelSaleConditionInput
  ) {
    deleteSale(input: $input, condition: $condition) {
      id
      product {
        id
        fangId
        name
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      date
      createdAt
      updatedAt
      saleProductId
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      ingredient {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      volume
      date
      createdAt
      updatedAt
      orderIngredientId
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      ingredient {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      volume
      date
      createdAt
      updatedAt
      orderIngredientId
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      ingredient {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      volume
      date
      createdAt
      updatedAt
      orderIngredientId
    }
  }
`;
export const createProductIngredient = /* GraphQL */ `
  mutation CreateProductIngredient(
    $input: CreateProductIngredientInput!
    $condition: ModelProductIngredientConditionInput
  ) {
    createProductIngredient(input: $input, condition: $condition) {
      id
      product {
        id
        fangId
        name
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      ingredient {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      createdAt
      updatedAt
      ingredientProductIngredientId
      productProductIngredientId
    }
  }
`;
export const updateProductIngredient = /* GraphQL */ `
  mutation UpdateProductIngredient(
    $input: UpdateProductIngredientInput!
    $condition: ModelProductIngredientConditionInput
  ) {
    updateProductIngredient(input: $input, condition: $condition) {
      id
      product {
        id
        fangId
        name
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      ingredient {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      createdAt
      updatedAt
      ingredientProductIngredientId
      productProductIngredientId
    }
  }
`;
export const deleteProductIngredient = /* GraphQL */ `
  mutation DeleteProductIngredient(
    $input: DeleteProductIngredientInput!
    $condition: ModelProductIngredientConditionInput
  ) {
    deleteProductIngredient(input: $input, condition: $condition) {
      id
      product {
        id
        fangId
        name
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      ingredient {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      createdAt
      updatedAt
      ingredientProductIngredientId
      productProductIngredientId
    }
  }
`;
