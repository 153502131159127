import React from "react"
import Datetime from "react-datetime";
import {DateTime} from "luxon";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import {Flex, View} from "@aws-amplify/ui-react";
import Button from "react-bootstrap/Button";
import {SkipBackward, SkipForward} from "react-bootstrap-icons";
import moment from "moment";

const DatePicker = ({selectedDate, setSelectedDate}) => {
  const isToday = selectedDate && selectedDate.isValid && selectedDate.hasSame(DateTime.local(), "day")


  const nextDay = () => {
    console.log(selectedDate)
    console.log("next day")
    if (!selectedDate.isValid) {
      setSelectedDate(DateTime.local().startOf('day'))
    }
    else {
      setSelectedDate(selectedDate.plus({days: 1}))
    }
  }

  return (

    <View as="form" margin="1rem 0rem 4rem 0rem">
    <Datetime
      renderInput={(props, openCalendar, closeCalendar) => {
        const d = DateTime.fromISO(props.value)
        let val = d.toLocaleString(DateTime.DATE_HUGE)

        if (!d.isValid)
          val = "All time"

        let relativeDate = ''
        if (d.hasSame(DateTime.local(), "day"))
          relativeDate = `Heute`
        else if (d.hasSame(DateTime.local().plus({ days: -1}), "day"))
          relativeDate = `Gestern`
        else if (d.hasSame(DateTime.local().plus({ days: -2}), "day"))
          relativeDate = `Vorgestern`

        return (
          <Container>
            <Row sm={1} className="justify-content-center">
              <Col lg={6}>
                <Flex direction='row'>
                  <Button variant="primary" disabled={!selectedDate.isValid} onClick={() => setSelectedDate(selectedDate.minus({days: 1}))}><SkipBackward /></Button>
                  {relativeDate && <h5><Badge bg="light" text="dark" style={{fontSize: '.9em', marginTop: '6px'}}>{relativeDate}</Badge></h5>}
                  <input {...props} value={val} />
                  <Button variant="primary" disabled={isToday} onClick={() => nextDay()}><SkipForward /></Button>
                  <Button variant='outline-secondary' onClick={() => props.onChange({target: {value: ''}})}>Clear</Button>
                </Flex>
              </Col>
            </Row>
          </Container>
        )

      }}
      dateFormat={'YYYY-MM-DDTHH'}
      timeFormat={false}
      value={selectedDate && selectedDate.isValid ? moment(selectedDate.toISODate()) : null}
      closeOnSelect={true}
      onChange={d => {
        if (d) d = d.toDate()
        console.log(DateTime.fromJSDate(d))
        setSelectedDate(DateTime.fromJSDate(d))
      }}
      isValidDate={d => !d.isAfter(moment())}
    />
  </View>
  );

}

export default DatePicker;