import React, {useState, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const EditStockModal = ({ingredient, setIngredient, doIngredientUpdate, setError}) => {

    const [newStock, setNewStock] = useState(ingredient.stock)
    const [minimum, setMinimum] = useState(ingredient.minimum)
    const [purchaseQuantity, setPurchaseQuantity] = useState(ingredient.purchaseQuantity)
    const [name, setName] = useState(ingredient.name)
    
    
    useEffect(() => {
        setNewStock(Math.round(ingredient.stock * 1000) / 1000)
        setMinimum(ingredient.minimum)
        setName(ingredient.name)
        setPurchaseQuantity(ingredient.purchaseQuantity)

    }, [ingredient])

    const handleClose = () => {
        setIngredient(false);
    }

    const doStockUpdate = () => {
        const stock = Math.round(parseFloat(newStock) * 1000) / 1000
        let newPurchaseQuantity = parseFloat(purchaseQuantity)
        let newMinimum = parseFloat(minimum)

        if (isNaN(stock)) {
          setError("Please enter a valid number...");
          return
        }
        if (isNaN(newMinimum)) newMinimum = 0
        if (isNaN(newPurchaseQuantity)) newPurchaseQuantity = 1
        
        ingredient.stock = stock
        ingredient.name = name
        ingredient.purchaseQuantity = newPurchaseQuantity
        ingredient.minimum = newMinimum

        doIngredientUpdate(ingredient);
        handleClose();
    }


    return (
      <Modal show={!!ingredient} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {`${ingredient.name}`} stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e)=>{e.preventDefault(); doStockUpdate()}}>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>Restmenge (in l)</Form.Label>
              <Col sm={8} >
                <Form.Control placeholder="New stock" value={newStock} onChange={e => setNewStock(e.target.value)} autoFocus />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>Minimum</Form.Label>
              <Col sm={8} >
                <Form.Control value={minimum} onChange={e => setMinimum(e.target.value)} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>Order quantity</Form.Label>
              <Col sm={8} >
                <Form.Control value={purchaseQuantity} onChange={e => setPurchaseQuantity(e.target.value)} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>Name</Form.Label>
              <Col sm={8} >
                <Form.Control value={name} onChange={e => setName(e.target.value)} />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {doStockUpdate()}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default EditStockModal;