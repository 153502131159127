import React, {useState, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const CreatePurchaseModal = ({ingredient, setIngredient, doPurchaseIngredient}) => {

    const [ unitsPurchased, setUnitsPurchased ] = useState('')
    const [volumeInL, setVolumeInL] = useState(0)
    useEffect(() => {
      setUnitsPurchased('')
      setVolumeInL(ingredient.purchaseQuantity || 1.0)
    }, [ingredient]);

    const handleClose = () => {
        setIngredient(false);
    }

    return (
        <Modal show={!!ingredient} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order {`${ingredient.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e)=>{e.preventDefault(); doPurchaseIngredient(ingredient, unitsPurchased, volumeInL); handleClose()}}>
            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
                Quantity: 
              </Form.Label>
              <Col sm={8} >
                <Form.Control placeholder="Amount ordered" value={unitsPurchased} onChange={e => setUnitsPurchased(e.target.value)} autoFocus />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" >
              <Form.Label column sm={4}>
                Of volume (l):
              </Form.Label>
              <Col sm={8} >
                <Form.Control placeholder="Volume" value={volumeInL} onChange={e => setVolumeInL(e.target.value)} />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {doPurchaseIngredient(ingredient, unitsPurchased, volumeInL); handleClose()}}>
            Add order
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
export default CreatePurchaseModal;