import React from "react"
import { Typeahead } from 'react-bootstrap-typeahead';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ref = React.createRef();


const IngredientSearch = ({selectIngredient, ingredients}) => {

  const filter = (option, props) => {
    if (props.text.length < 1) return false
    return option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
  }

  const onIngredientSelected = (selection) => {
    ref.current.clear()
    selectIngredient(selection[0])
  }


  return (
    <Container as="form" margin="1rem 0rem 4rem 0rem">
      <Container>
      <Row sm={1} className="justify-content-center">
        <Col md={6}>


            <Typeahead
              ref={ref}
              id='product-search'
              placeholder="Artikle Name eingaben"
              selected={null}
              options={ingredients || []}
              filterBy={filter}
              labelKey= {option => `${option.name}`}
              onChange={onIngredientSelected}
              highlightOnlyResult={true}
              autoFocus
              autocomplete="off"

            />

        </Col>
      </Row>
    </Container>
    </Container>
  )

}

export default  IngredientSearch