import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import { listIngredients, listOrders } from '../graphql/queries';
import { createOrder, deleteOrder, updateIngredient } from '../graphql/mutations';

import { View } from "@aws-amplify/ui-react";
import Container from 'react-bootstrap/Container';

import DatePicker from "../components/DatePicker";
import PurchasesList from "../components/PurchasesList";
import IngredientSearch  from '../components/IngredientSearch';
import CreatePurchaseModal from '../components/CreatePurchaseModal';

import { DateTime } from "luxon";


function Purchases() {
    const [selectedDate, setSelectedDate] = useState(DateTime.local().startOf('day'))
    const [purchases, setPurchases] = useState([])
    const [ingredients, setIngredients] = useState([])
    const [selectedIngredient, selectIngredient] = useState(false);


    const fetchPurchases = async () => {
      const variables = selectedDate.isValid && {
        filter: {
          date: {
            eq: selectedDate.toISODate()
          }
        }
      };

      try {
        const purchasesData = await API.graphql(graphqlOperation(listOrders, variables || {}))
        const purchasesItems = purchasesData.data.listOrders.items
        purchasesItems.forEach(p => p.date = DateTime.fromISO(p.date).startOf('day'))
        setPurchases(purchasesItems)
      } catch (err) {
        console.log("error:", err)
      }
    }

    const fetchIngredients = async () => {
      try {
        const ingredientsData = await API.graphql(graphqlOperation(listIngredients))
        setIngredients(ingredientsData.data.listIngredients.items)
      } catch (err) {
        console.log(err)
      }
    } 

    useEffect(() => {
      fetchIngredients();
    }, [])

    useEffect(() => {
      fetchPurchases();
    }, [selectedDate])

    
    const [filteredPurchases, setFilteredPurchases] = useState([])
    useEffect(() => {
      const filteredPs = purchases.filter(purchase => {
        if (!selectedDate || !selectedDate.isValid) return purchase;
        return purchase.date.startOf('day').toMillis() === selectedDate.toMillis();
      });
      setFilteredPurchases(filteredPs)
    }, [purchases, selectedDate]);


    const doPurchaseIngredient = async (ingredient, quantity, volume) => {

      let purchaseQuantity = parseFloat(quantity)
      let purchaseVolume = parseFloat(volume) || 1.

      await API.graphql(graphqlOperation(createOrder, {input: {
        orderIngredientId: ingredient.id,
        quantity: purchaseQuantity,
        volume: purchaseVolume,
        date: selectedDate.toISODate()
      }}))
      
      await API.graphql(graphqlOperation(updateIngredient, {input: {
        id: ingredient.id,
        stock: ingredient.stock + (purchaseQuantity * purchaseVolume)
      }}))

      fetchPurchases();
    }

    const doDeletePurchase = async (purchase) => {
        await API.graphql(graphqlOperation(deleteOrder, {input: {id: purchase.id}}))
        await API.graphql(graphqlOperation(updateIngredient, {input: {
          id: purchase.ingredient.id,
          stock: purchase.ingredient.stock - (purchase.quantity * purchase.volume)
        }}))
        // todo: delete locally
        fetchPurchases();
    }

    return (

    <View className="App">

      <main className="container bg-body shadow-sm rounded" style={{minHeight: 'calc(100vh - 200px)'}}>
        <Container className="">
          <DatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </Container>

        <Container className="">
          <IngredientSearch ingredients={ingredients} selectIngredient={selectIngredient}/>
        </Container>

        <Container  className="">
          <PurchasesList purchases={filteredPurchases} deletePurchase={doDeletePurchase}/>
        </Container>
      </main>

      <CreatePurchaseModal ingredient={selectedIngredient} setIngredient={selectIngredient} doPurchaseIngredient={doPurchaseIngredient}/>

    </View>

    );
}

export default Purchases;