import React, {useState, useEffect} from "react"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import { API, graphqlOperation } from 'aws-amplify';

import { listIngredients } from '../graphql/queries';
import { updateIngredient } from '../graphql/mutations';

import ProductSearch from "../components/ProductSearch";
import EditStockModal from '../components/EditStockModal';
import ErrorModal from '../components/ErrorModal';
import { Pencil } from 'react-bootstrap-icons';



const StockRow = (({i, setEditingIngredient}) => {

  const remainingStock = i.stock - i.minimum
  const warningLimit = i.warning || i.minimum * 1.2

  let status = 'good'
  if (i.stock < warningLimit) {
    status = 'warning'
  }
  if (i.stock < i.minimum) {
    status = 'danger'
  }


  return (
    <tr>
      <td>{i.name}</td>
      <td>{Math.round(i.stock * 10)/10} {i.purchaseQuantity !== null && i.purchaseQuantity !== 0 && i.purchaseQuantity !== 1 && ` (${Math.round(i.stock/i.purchaseQuantity).toString()} stk)`}</td>
      <td>
        {status === 'good' && (<Badge bg='success' pill style={{fontSize: '.9em'}}>{Math.round(i.stock - i.minimum)}l bzm</Badge>)}
        {status === 'warning' && (<Badge bg='warning' pill text="dark" style={{fontSize: '.9em'}}>{Math.round(i.stock - i.minimum)}l bzm</Badge>)}
        {status === 'danger' && (<Badge bg='danger' style={{fontSize: '.9em'}}>{-1 * Math.round(i.stock - i.minimum)} L unter Minimum</Badge>)}
      </td>
      <td><Pencil style={{cursor: "pointer"}} onClick={() => setEditingIngredient(i)} /></td>
    </tr>
  )

})

const Stock = () => {

  const [filter, setFilter] = useState('')
  const [ingredients, setIngredients] = useState([])
  const [editingIngredient, setEditingIngredient] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  useEffect(() => {
    async function fetchIngredients() {
      try {
        const ingredientsData = await API.graphql(graphqlOperation(listIngredients))
        const ingredients = ingredientsData.data.listIngredients.items
        setIngredients(ingredients)
      } catch (err) {
        console.log(err)
      }
    } 
    fetchIngredients()   
  }, [])

  const doIngredientUpdate = async (ingredient) => {
    await API.graphql(graphqlOperation(updateIngredient, {input: {
      id: ingredient.id,
      name: ingredient.name,
      stock: ingredient.stock,
      minimum: ingredient.minimum,
      purchaseQuantity: ingredient.purchaseQuantity
    }}))
  }

  const filteredIngredients = ingredients.filter(i => {
    if (filter === '') return true

    return i.name.toLowerCase().includes(filter.toLowerCase())
  })

  filteredIngredients.sort((a,b) => {
    a.remaining = a.stock - a.minimum
    b.remaining = b.stock - b.minimum
    return (a.remaining > b.remaining) ? 1 : ((b.remaining > a.remaining) ? -1 : 0)
  })

  return (
    <Container>
      <main className="container bg-body shadow-sm rounded" style={{minHeight: 'calc(100vh - 200px)'}}>

      <Container style={{margin: "1rem 0rem 4rem 0rem"}}>
        <Row sm={1} className="justify-content-center">
          <Col md={6}>
            <Form.Control  placeholder="Artikel suchen..." defaultValue={filter} onChange={e => setFilter(e.target.value)}/>
          </Col>
        </Row>
      </Container>

      <Container className="my-3 p-3">
        <Table>
          <thead>
          <tr>
            <th>Artikel</th>
            <th>Restmenge auf Lager</th>
            <th>Status</th>
            <th>Edit</th>
          </tr>
          </thead>

          <tbody>
            {filteredIngredients.map(i => <StockRow key={i.id} i={i} setEditingIngredient={setEditingIngredient} />)}

          </tbody>

        </Table>
      </Container>
      </main>


      <EditStockModal ingredient={editingIngredient} doIngredientUpdate={doIngredientUpdate} setIngredient={setEditingIngredient} setError={setErrorMsg} />
      <ErrorModal errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
    </Container>

      )
}

export default Stock