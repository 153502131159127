import React, { useState, useEffect } from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  View,
} from "@aws-amplify/ui-react";

import Datetime from 'react-datetime';
import moment from "moment";

import { DateTime } from "luxon";
import ProductSearch  from "../components/ProductSearch";
import TransactionList from "../components/transactions";
import ErrorModal from "../components/ErrorModal";
import DatePicker from "../components/DatePicker";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';

import { API, graphqlOperation } from 'aws-amplify';
import { listProducts, listSales } from '../graphql/queries';
import { createSale, deleteSale, updateIngredient } from '../graphql/mutations';

function Sales() {

  const [products, setProducts] = useState([])
  const [sales, setSales] = useState([])
  const [selectedDate, setSelectedDate] = useState(DateTime.local().startOf('day'))
  const [unitsSold, setUnitsSold] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const fetchData = async () => {
    try {
      const productsData = await API.graphql(graphqlOperation(listProducts))
      const products = productsData.data.listProducts.items
      setProducts(products)
    } catch (err) {
      console.log(err)
    }
  } 

  const fetchSales = async () => {
    const variables = selectedDate.isValid && {
      filter: {
        date: {
          eq: selectedDate.toISODate()
        }
      }
    }; 

    try {
      const salesData = await API.graphql(graphqlOperation(listSales, variables || {}))
      const salesItems = salesData.data.listSales.items
      salesItems.forEach(sale => sale.date = DateTime.fromISO(sale.date).startOf('day'))
      setSales(salesItems)
    } catch (err) {
      console.log("error:", err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    fetchSales()    
  }, [selectedDate])

  const filteredSales = sales.filter(sale => {
    if (!selectedDate || !selectedDate.isValid) return sales;
    return sale.date.startOf('day').toMillis() === selectedDate.toMillis()
  })

  const [product, setProduct] = useState(false)
  const handleClose = () => setProduct(false);

  const showProductModal = (product) => {
    const saleDate = selectedDate || DateTime.local().startOf('day')
    let previousSale = false;

    sales.forEach(s => {
      if (s.product.id === product.id && s.date.toMillis() == saleDate.toMillis() )
        previousSale = s;
    })
    if (previousSale) {
      setErrorMsg(`You've already sold ${previousSale.quantity} of ${previousSale.product.name} on ${previousSale.date.toFormat('MM-dd-yy')}`)
    }
    else {
      setProduct(product)
    }
  }

  const createCompleteSale = async (product, quantity) => {
    const saleDate = selectedDate || DateTime.local().startOf('day')
    quantity = parseInt(quantity)
    const saleData = {
      saleProductId: product.id,
      quantity: quantity,
      date: saleDate.toISODate()
    }
    const savedSale = await API.graphql(graphqlOperation(createSale, {input: saleData}))
    const sale = savedSale.data.createSale
    sale.date = DateTime.fromISO(sale.date).startOf('day')

    sales.push(sale)
    setSales(sales)
    setUnitsSold('')

    const ingredientData = product.productIngredient.items
    
    await Promise.all(ingredientData.map(async i => {
      const ingredient = i.ingredient
      ingredient.stock -= (i.quantity * sale.quantity)
      return API.graphql(graphqlOperation(updateIngredient, {input: ingredient}));
    }))
  }

  const doDeleteSale = async (sale) => {
    const product = sale.product
    console.log(product)
    const ingredientsData = product.productIngredient.items 

    await Promise.all(ingredientsData.map(async i => {
      const ingredient = i.ingredient
      console.log(i, sale, ingredient.stock)
      ingredient.stock += (i.quantity * sale.quantity)
      return API.graphql(graphqlOperation(updateIngredient, {input: ingredient}));
    }))
    
    await API.graphql(graphqlOperation(deleteSale, {input: {id: sale.id}}))
    let newSales = sales.filter(s => s.id !== sale.id)
    setSales(newSales)
  }


  return (
    <View className="App">

      <ErrorModal errorMsg={errorMsg} setErrorMsg={setErrorMsg} />

      <main className="container bg-body shadow-sm rounded" style={{minHeight: 'calc(100vh - 200px)'}}>
        <Container className="">
          <DatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </Container>

        <Container className="">
          <ProductSearch selectProduct={showProductModal} products={products}/>
        </Container>

        <Container  className="">
          <TransactionList sales={filteredSales} deleteSale={doDeleteSale}/>
        </Container>
      </main>

      <Modal show={!!product} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`${product.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e)=>{e.preventDefault(); createCompleteSale(product, unitsSold); handleClose()}}>
            <Form.Control placeholder="Units sold..." value={unitsSold} onChange={e => setUnitsSold(e.target.value)} autoFocus />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {createCompleteSale(product, unitsSold); handleClose()}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </View>
  );
}

export default Sales;