import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';


const ErrorModal = ({errorMsg, setErrorMsg}) => {
    return (
      <Modal show={!!errorMsg}>
        <Alert variant="danger" onClose={() => setErrorMsg(false)} dismissible>
          <Alert.Heading></Alert.Heading>
          <p>
            {errorMsg}
          </p>
        </Alert>
      </Modal>
    );
}

export default ErrorModal;