import React, { useEffect, useState } from 'react';
import {
    View,
} from "@aws-amplify/ui-react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import { Trash } from 'react-bootstrap-icons';

import { API, graphqlOperation } from 'aws-amplify';
import { listProducts, listIngredients } from '../graphql/queries';
import { createProductIngredient, createProduct, deleteProductIngredient, deleteProduct } from '../graphql/mutations';

const AdminPage = () => {
    const [products, setProducts] = useState([])
    const [ingredients, setIngredients] = useState([])
    const [selectedProductId, selectProduct] = useState(false);
    const [selectedIngredientId, selectIngredient] = useState(false);
    const [ingredientAmount, setIngredientAmount] = useState(0.5);
    const [productName, setProductName] = useState('')
    const [fangId, setFangId] = useState('')

    const fetchProducts = async () => {
        try {
            const productsData = await API.graphql(graphqlOperation(listProducts))
            const products = productsData.data.listProducts.items
            products.sort((a,b) => (a.fangId > b.fangId) ? 1 : ((b.fangId > a.fangId) ? -1 : 0))

            setProducts(products)
          } catch (err) {
            console.log(err)
          }
    }

    useEffect(() => {
        const fetchIngredients = async () => {
            try {
                const data = await API.graphql(graphqlOperation(listIngredients))
                const ingredients = data.data.listIngredients.items
                ingredients.sort((a,b) => {
                    return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
                  })
                setIngredients(ingredients)
              } catch (err) {
                console.log(err)
              }
        }

        
        fetchProducts()
        fetchIngredients()
    }, [])

    const createProductIngredientLink = async () => {
        console.log("Creating PI")
        const linkData = {
            ingredientProductIngredientId: selectedIngredientId,
            productProductIngredientId: selectedProductId,
            quantity: parseFloat(ingredientAmount)
        }
        await API.graphql(graphqlOperation(createProductIngredient, {input: linkData }));
        // await fetchProducts()
    }
    const doCreateProduct = async() => {
        const productData = {
            fangId: fangId,
            name: productName
        }
        await API.graphql(graphqlOperation(createProduct, {input: productData}));
        setProductName('');
    }

    const doProductIngredientDelete = async id => {
        await API.graphql(graphqlOperation(deleteProductIngredient, {input: {id: id}}))
        await fetchProducts()
    }
    const doProductDelete = async id => {
        await API.graphql(graphqlOperation(deleteProduct, {input: {id: id}}))
        await fetchProducts()
    }
    const handleProductSelected = (e) => {
        
    }

    return (
        <View className="App">

            <main className="container bg-body shadow-sm rounded" style={{minHeight: 'calc(100vh - 200px)'}}>

                <Container>
                <Form onSubmit={e => {e.preventDefault()}}>
                    <select value={selectedProductId} onChange={e => {selectProduct(e.target.value)}}>
                        <option></option>
                        {products.map(p => <option value={p.id}>{p.name}</option>)}
                    </select>

                    <select value={selectedIngredientId} onChange={e => {selectIngredient(e.target.value)}}>
                        <option></option>
                        {ingredients.map(i => <option value={i.id}>{i.name}</option>)}
                    </select>
                    <Form.Control value={ingredientAmount} onChange={e => setIngredientAmount(e.target.value)} />

                    <Button type="primary"  onClick={createProductIngredientLink}>Create Product Ingredient Link</Button>
                </Form>
                </Container>

                <Container style={{paddingTop: '100px'}}>
                <Form onSubmit={e => {e.preventDefault()}}>
                    <Form.Control placeholder="Product name" value={productName} onChange={e => setProductName(e.target.value)} />
                    <Form.Control placeholder="Fang ID" value={fangId} onChange={e => setFangId(e.target.value)} />
                    <Button type="primary"  onClick={doCreateProduct}>Create Product</Button>

                </Form>

                </Container>
                

                <Container style={{paddingTop: '100px'}}>
                <Table size="sm" style={{width: '700px'}}>
                    <thead>
                        <th style={{width: '200px'}}>Product Fang ID</th>
                        <th>Product Name</th>
                        <th>Ingredient</th>
                        <th>Quantity</th>
                    </thead>
                    <tbody>
                    {products.map(p => 
                    <React.Fragment>
                        <tr>
                            <td><b>{p.fangId}</b></td>
                            <td><b>{p.name}</b></td>
                            <td></td>
                            <td></td>
                            <td><Trash onClick={() => doProductDelete(p.id)} /></td>
                        </tr>
                        {p.productIngredient.items.map(pi => <tr>
                            <td></td>
                            <td></td>
                            <td>{pi.ingredient && pi.ingredient.name}</td>
                            <td>{pi.quantity}</td>
                            <td><Trash onClick={() => doProductIngredientDelete(pi.id)} /></td>
                        </tr>)}
                    </React.Fragment>
                    
                    )}
                    </tbody>
                </Table>

                </Container>
                


            </main>
        </View>
    )
}

export default AdminPage;