/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIngredient = /* GraphQL */ `
  query GetIngredient($id: ID!) {
    getIngredient(id: $id) {
      id
      name
      stock
      minimum
      purchaseQuantity
      productIngredient {
        items {
          id
          quantity
          createdAt
          updatedAt
          ingredientProductIngredientId
          productProductIngredientId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listIngredients = /* GraphQL */ `
  query ListIngredients(
    $filter: ModelIngredientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIngredients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      fangId
      name
      productIngredient {
        items {
          id
          quantity
          createdAt
          updatedAt
          ingredientProductIngredientId
          productProductIngredientId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: 1000, nextToken: $nextToken) {
      items {
        id
        fangId
        name
        productIngredient {
          items {
            id
            ingredient {
              id
              name
              stock
            }
            quantity
          }
        }
      }
      nextToken
    }
  }
`;
export const getSale = /* GraphQL */ `
  query GetSale($id: ID!) {
    getSale(id: $id) {
      id
      product {
        id
        fangId
        name
        productIngredient {
          nextToken
        }
      }
      quantity
      date
    }
  }
`;
export const listSales = /* GraphQL */ `
  query ListSales(
    $filter: ModelSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        product {
          id
          fangId
          name
          productIngredient {
            items {
              ingredient {
                id
                name
                stock
              }
              quantity
            }
          }
        }
        quantity
        date
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      ingredient {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
      }
      quantity
      volume
      date
      createdAt
      updatedAt
      orderIngredientId
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ingredient {
          id
          name
          stock
          minimum
          purchaseQuantity
        }
        quantity
        volume
        date
      }
      nextToken
    }
  }
`;
export const getProductIngredient = /* GraphQL */ `
  query GetProductIngredient($id: ID!) {
    getProductIngredient(id: $id) {
      id
      product {
        id
        fangId
        name
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      ingredient {
        id
        name
        stock
        minimum
        purchaseQuantity
        productIngredient {
          nextToken
        }
        createdAt
        updatedAt
      }
      quantity
      createdAt
      updatedAt
      ingredientProductIngredientId
      productProductIngredientId
    }
  }
`;
export const listProductIngredients = /* GraphQL */ `
  query ListProductIngredients(
    $filter: ModelProductIngredientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductIngredients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        product {
          id
          fangId
          name
          createdAt
          updatedAt
        }
        ingredient {
          id
          name
          stock
          minimum
          purchaseQuantity
          createdAt
          updatedAt
        }
        quantity
        createdAt
        updatedAt
        ingredientProductIngredientId
        productProductIngredientId
      }
      nextToken
    }
  }
`;
