import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import {LinkContainer} from 'react-router-bootstrap'

import { useAuthenticator } from '@aws-amplify/ui-react';


const ShrankNav = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
  <Navbar bg="dark" variant="dark">
    <Container>
      <Navbar.Brand href="#home">
          <img src="/logo.jpeg" style={{width: '90px'}} />
      </Navbar.Brand>

      <Nav className="me-auto">
        <LinkContainer to="/stock">
          <Nav.Link>Ubersicht</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/sales"><Nav.Link>Verkäufe</Nav.Link></LinkContainer>
        <LinkContainer to="/purchases"><Nav.Link>Einkaufe</Nav.Link></LinkContainer>
      </Nav>

      <Nav className="justify-content-end">
      <Navbar.Text>
          <a href="#" onClick={signOut}>Logout</a>
      </Navbar.Text>
      </Nav>
  </Container>
  </Navbar>
  )
}


export default ShrankNav