import React, {useState} from 'react';
import Table from 'react-bootstrap/Table';
import { Trash, SortDown, SortUp } from 'react-bootstrap-icons';

const TransactionList = ({sales, deleteSale}) => {

  const [sortOn, setSortOn] = useState('product.id')
  const [sortAscending, setSortAscending] = useState(true)

  const setSort = (newSortOn => {
    if (sortOn == newSortOn) setSortAscending(!sortAscending)
    setSortOn(newSortOn)
  })

  sales.sort((a,b) => {

    const getVal = (x) => {
      const path = sortOn.split('.')
      if (path.length == 1) return x[sortOn]
      return x[path[0]][path[1]]
    }

    return (getVal(a) > getVal(b)) ? 1 : ((getVal(b) > getVal(a)) ? -1 : 0)
  })

  if (sortAscending) sales.reverse()

  const SortIcon = sortAscending ? SortDown : SortUp


  return (
    <Table>
      <thead>
        <tr>
          <th onClick={() => setSort('date')}>Date {sortOn === 'date' && <SortIcon />}</th>
          <th onClick={() => setSort('product.id')}>Artikle ID {sortOn === 'product.id' && <SortIcon />}</th>
          <th onClick={() => setSort('product.name')}>Artikle Name {sortOn === 'product.name' && <SortIcon />}</th>
          <th onClick={() => setSort('quantity')}>Anzhal {sortOn === 'quantity' && <SortIcon />}</th>
          <th></th>
        </tr>
      </thead>

      <tbody>

      {sales.map(sale => (
        <tr key={sale.id}>
          <td>{sale.date.toFormat('MM-dd')}</td>
          <td>{sale.product.fangId}</td>
          <td>{sale.product.name}</td>
          <td>{sale.quantity}</td>
          <td><Trash style={{cursor: "pointer"}} onClick={() => deleteSale(sale)} /></td>
        </tr>
      ))}


      </tbody>

    </Table>

  )



}

export default TransactionList