import React, { useState } from 'react';

import { Route, Routes } from "react-router-dom"

import Sales from "./pages/sales";
import Stock from "./pages/stock";
import Purchases from "./pages/purchases";
import ShrankNav from "./components/Nav"
import Admin from './pages/admin';

import {
  withAuthenticator,
} from "@aws-amplify/ui-react";

function App({ signOut }) {

  return (
    <>
      <ShrankNav />

      <Routes>
        <Route path="/" element={<div />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/stock" element={<Stock />} />
        <Route path='/purchases' element={<Purchases />} />
        <Route path='/admin' element={<Admin />} />
      </Routes>
    </>

  )


}
export default (withAuthenticator(App));